














































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store/store";
import ApiButton from "@/vue/components/ApiButton.vue";
import ChangePasswordDialogue from "@/vue/components/ChangePasswordDialogue.vue";
import { User } from "@/model/User";

@Component({
    components: { ApiButton, ChangePasswordDialogue }
})
export default class Profile extends Vue {

    //
    // -- lifecycle hooks
    //

    async mounted() {
        if (store.state.signedInUser == null) {
            await store.dispatch("loadSignedInUser");
        }
        if (store.state.signedInUser !== null) {
            this.user = store.state.signedInUser;
        }
    }

    //
    // -- properties
    //

    private user = new User();

    //
    // -- methods
    //

    private changePassword() {
        const dialogue: ChangePasswordDialogue = this.$refs.changePasswordDialogue as ChangePasswordDialogue;
        dialogue.changePassword(this.user);
    }

}
